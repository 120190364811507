import 'date-fns';
import React, { Fragment, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import homeloan from "../../assets/images/homeloan.jpg";
import s1llogo from "../../assets/images/s1llogo.png";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { getLoanAndBorrowers, getBorrowerAndLoans } from "services";
// import { getLoanDetails } from '../../services/loanLookupService';
import { useHistory } from "react-router-dom";
import "./LoginPage.css";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  LOAN_AND_BORROWERS,
  BORROWER_AND_LOANS,
} from "../../redux/actions/loginActions";
import { SYSTEM_ERROR } from "../../config/CONSTANTS";
import BorrowersByLoanForm from "../../components/LoginScreen/BorrowersByLoanForm";
import LoansByBorrowerForm from "../../components/LoginScreen/LoansByBorrowerForm";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import FooterNew from 'pages/CommonComponents/Footer-new';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px #87CEFA",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  
  const [loanDetails, setLoanDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBorrowersByLoanActive, setBorrowersByLoanActive] = useState(false);
  const [showBorrowersByLoanForm, setShowBorrowersByLoanForm] = useState(true);
  let history = useHistory();

  const changeShowBorrowersByLoanForm = () => {
    setBorrowersByLoanActive(true);
    reset();
    setShowBorrowersByLoanForm(!showBorrowersByLoanForm);
  };

  function callLoanAndBorrowers(bodyData) {
    setIsLoading(true);
    getLoanAndBorrowers(bodyData)
      .then((res) => {
        dispatch(LOAN_AND_BORROWERS(res));
        setLoanDetails(res);
        if (parseInt(res.loanInfo.loanDetailId) !== 0) {
          history.push({
            pathname: "/LoanLookupShowData",
            loanDetails: res,
          });
        } else {
          Swal.fire(
            "Loan does not exist.",
            "Please contact Loan Servicing at 888-689-0511 or servicing@s1l.com",
            "error"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(SYSTEM_ERROR, "", "error");
        setLoanDetails({});
        setIsLoading(false);
      });
  }

  function callBorrowerAndLoans(bodyData) {
    setIsLoading(true);
    getBorrowerAndLoans(bodyData)
      .then((res) => {
        dispatch(BORROWER_AND_LOANS(res));
        setLoanDetails(res);
        if (res.loans.length > 0) {
          history.push({
            pathname: "/LoanLookupShowData",
            loanDetails: res,
          });
        } else {
          Swal.fire(
            "Loan does not exist.",
            "Please contact Loan Servicing at 888-689-0511 or servicing@s1l.com",
            "error"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(SYSTEM_ERROR, "", "error");
        setLoanDetails({});
        setIsLoading(false);
      });
  }

  const onSubmit = (data) => {
    let bodyData;
    showBorrowersByLoanForm
      ? (bodyData = {
          loanNumber: data.loanNumber,
          lastFourDigitsOfSSN: data.ssn,
        })
      : (bodyData = {
          lastName: data.lastName,
          borrowerDOB: data.borrowerDOB,
          lastFourDigitsOfSSN: data.ssn,
        });
    showBorrowersByLoanForm
      ? callLoanAndBorrowers(bodyData)
      : callBorrowerAndLoans(bodyData);
  };

  return (
    <Fragment>
      <CssBaseline />
      {isLoading ? <LoadingSpinner /> : ""}
      {/* <Container maxWidth={false} className="check" > */}
      <Grid container className="" spacing={2}>
        <Grid
          className=" show_hide_block sideLogoBg"
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
        >
          <img src={homeloan} alt="avtar" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="formContainer" onSubmit={handleSubmit(onSubmit)}>
            <img src={s1llogo} alt="s1llogo" className="s1lLogo" />
            {/* <Typography component="h1" variant="h4" gutterBottom>
                            Welcome back
                        </Typography> */}
            <Typography
              component="h5"
              variant="h5"
              gutterBottom
              color="secondary"
            >
              Loan Lookup{" "}
            </Typography>
            <hr />
            <div>
              {/* <Typography
                onClick={changeShowBorrowersByLoanForm}
                paragraph
                component="span"
                color="secondary"
                variant="body1"
              >
                I don't know my Loan number{" "}
              </Typography> */}
              <a
                href="javascript:void(0);"
                className={showBorrowersByLoanForm ? "active" : "inActive"}
                onClick={changeShowBorrowersByLoanForm}
              >
                I know my Loan number
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="javascript:void(0);"
                className={!showBorrowersByLoanForm ? "active" : "inActive"}
                onClick={changeShowBorrowersByLoanForm}
              >
                I don't know my Loan number
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Typography
                onClick={changeShowBorrowersByLoanForm}
                paragraph
                component="span"
                color="secondary"
                variant="body1"
              >
                or search borrowers by loan{" "}
              </Typography> */}
            </div>
            {showBorrowersByLoanForm ? (
              <Fragment>
                {/* <Typography
                  onClick={changeShowBorrowersByLoanForm}
                  paragraph
                  component="span"
                  color="secondary"
                  variant="body1"
                >
                  or search loans by borrower{" "}
                </Typography>               */}
                <BorrowersByLoanForm
                  classes={classes}
                  register={register}
                  errors={errors}
                />
              </Fragment>
            ) : (
              <Fragment>
                <LoansByBorrowerForm
                  classes={classes}
                  register={register}
                  errors={errors}
                />
              </Fragment>
            )}
          </div>{" "}
        </Grid>
      </Grid>
     <FooterNew />
      {/* </Container> */}
    </Fragment>
  );
}
export default LoginPage;
