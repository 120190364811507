import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../CommonComponents/Header";
import { useSelector } from "react-redux";
import LoanDetail from "../../components/LoanLookup/LoanDetail";
import FooterNew from "pages/CommonComponents/Footer-new";

const useStyles = makeStyles({
  table: {},
  textBold: {
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
  },
  userDetailText: {
    color: "#454746",
    fontWeight: "bold",
    fontSize: "20px",
  },
  loanDetailText: {
    color: "#454746",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "right",
  },
  userEmailText: {
    color: "#454746",
    fontWeight: "bold",
    fontSize: "14px",
  },
  removeLink: {
    textDecoration: "none",
    color: '#ffffff'
  },
});

const CustomizedTables = () => {
  const loanInfo = useSelector((state) => state.loginUser);
  const s1l = "s1l";
  const serviceReleased = "Service Released";
  const serviceRetained = "Service Retained";
  const history = useHistory();
  const classes = useStyles();
  const [loanData, setLoanData] = useState(null);

  useEffect(() => {
    transformData(loanInfo);
  }, [loanInfo]);

  const transformData = (loanInfo) => {
    const { loanAndBorrowers, borrowerAndLoans } = loanInfo;
    if (Object.keys(loanAndBorrowers).length > 0) {
      setLoanData({
        multiple: "borrowers",
        loanInfo: loanAndBorrowers.loanInfo,
        borrowerInfo: loanAndBorrowers.borrowers,
      });
    } else {
      setLoanData({
        multiple: "loans",
        loanInfo: borrowerAndLoans.loans,
        borrowerInfo: borrowerAndLoans.borrowerInfo,
      });
    }
  };

  return (
    <div>
      <Header />
      <Container maxWidth="lg" style={{ marginTop: 20 }}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={10}
            lg={8}
            component={Paper}
            style={{ borderColor: "red" }}
          >
            {loanData && (
              <LoanDetail
                classes={classes}
                history={history}
                loanData={loanData}
                s1l={s1l}
                serviceReleased={serviceReleased}
                serviceRetained={serviceRetained}
              />
            )}
          </Grid>{" "}
        </Grid>
      </Container>
      
      <FooterNew />
    </div>
  );
};

export default CustomizedTables;
